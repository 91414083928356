<template>
  <div
    :class="{
      'e-message--read': data.read,
      'e-message--answered': !data.draft && data.outgoing,
      'e-message--draft': data.draft && data.outgoing,
      'e-message--open': isOpen
    }"
    class="e-message e-message--ingoing"
    role="link"
    tabindex="0"
    @click="toggleDialog"
    @keyup.enter="toggleDialog"
  >
    <div v-if="dialogLoading.indexOf(data.id) > -1" class="e-message__loading">
      <loading />
    </div>

    <div class="e-message__meta">
      <div
        class="e-message__author"
        v-text="data.outgoing ? $t('system.me') : data.author"
      />

      <time :datetime="data.timestamp" class="e-message__date">
        {{ data.timestamp | simpledate }}
      </time>
    </div>

    <div class="e-message__body">
      <!-- Unread icon -->
      <IconArrowIncomingFull
        v-if="!data.read && !data.outgoing"
        class="e-message__icon"
      />

      <!-- Read icon -->
      <IconArrowIncomingLine
        v-else-if="data.read && !data.outgoing"
        class="e-message__icon"
      />

      <!-- Answered icon -->
      <IconArrowOutgoingLine
        v-else-if="!data.draft && data.outgoing"
        class="e-message__icon"
      />

      <!-- Draft icon -->
      <IconPencil
        v-else-if="data.draft && data.outgoing"
        class="e-message__icon"
      />

      <h3
        class="e-message__subject"
        v-text="data.subject || $t('system.noSubject')"
      />

      <div class="e-message__count" v-text="data.threadCount" />

      <p class="e-message__content" v-html="trimContent($sanitizeStrict(data.body))" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from 'atoms/Loading/Loading'

// Icons
import IconArrowIncomingFull from 'assets/icons/arrow-incoming-full.svg'
import IconArrowIncomingLine from 'assets/icons/arrow-incoming-line.svg'
import IconArrowOutgoingLine from 'assets/icons/arrow-outgoing-line.svg'
import IconPencil from 'assets/icons/pencil-outline.svg'

export default {
  name: 'MessageListItem',
  components: {
    IconArrowIncomingFull,
    IconArrowIncomingLine,
    IconArrowOutgoingLine,
    IconPencil,
    Loading
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['dialogLoading']),

    isOpen() {
      return (
        (this.$route.params.dialogId === this.data.id &&
          !this.$route.params.messageId) ||
        (this.$route.params.dialogId === this.data.rootId &&
          this.$route.params.messageId === this.data.id)
      )
    }
  },
  methods: {
    trimContent(content) {
      let simpleContent = content
        .replace(/<br\s*[\/]?>/gi, ' ') // eslint-disable-line
        .replace('</div><div>', ' ')
        .replace('<div>', ' ')
      const div = document.createElement('div')
      div.innerHTML = simpleContent
      simpleContent = div.textContent || div.innerText || ''
      return simpleContent.length > 100
        ? `${simpleContent.substring(0, 100)}...`
        : simpleContent
    },

    toggleDialog() {
      const dialogId = this.data.rootId || this.data.id
      const messageId = this.data.rootId ? this.data.id : false

      if (
        this.$route.params.dialogId &&
        ((!this.$route.params.messageId &&
          this.$route.params.dialogId === this.data.id) ||
          (this.$route.params.messageId &&
            ((this.$route.params.dialogId !== this.data.id &&
              this.$route.params.messageId === this.data.id &&
              this.data.parentId !== null) ||
              (this.$route.params.dialogId === this.data.id &&
                this.$route.params.messageId === this.data.id &&
                this.data.parentId === null))))
      ) {
        // if (this.$route.params.dialogId && (this.$route.params.dialogId === this.data.id || this.$route.params.dialogId === this.data.rootId)) {
        this.$router.push({
          name: this.$route.meta.parentRoute
        })
      } else {
        /**
         * Go To "New Dialog" if only 1 element is present
         */
        if (this.data.threadCount === 1 && this.data.draft === true) {
          this.$router.push({
            name: 'newDialog',
            params: {
              dialogId: this.data.id
            }
          })
        } else {
          const params = {}
          params.dialogId = dialogId

          if (messageId) params.messageId = messageId

          this.$router.push({
            name: this.$route.meta.detailsRoute,
            params: params
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/messagepreview';
</style>
